const Favourites = {
  en: {
    title: "Favourites",
  },
  ka: {
    title: "ფავორიტები",
  },
  ru: {
    title: "Избранное",
  },
};

export default Favourites;
