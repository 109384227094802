const Chat = {
  en: {
    title: "Chat",
    recently: "Recently",
    followings: "Followings",
    findUser: "Find user...",
    typeText: "Type text...",
    deleteChatText: "Are you sure you want to delete the chat?",
    confirm: "Confirm",
    confirmText: "Are you sure you want to delete the text?",
    cancel: "Cancel",
    remove: "Remove",
    notFound: "Chats not found",
    aiAssistant: "Beauty Assistant",
    assistantOffer:
      "isn't online, you can start conversation with AI Assistant",
    disableBot: "Disable Bot",
    assistantTyping: "Assistant is typing",
    consultantTyping: "Consultant is typing",
    aiConsultant: "AI Consultant",
  },
  ka: {
    title: "სასაუბრო",
    recently: "უახლესი",
    followings: "ფავორიტები",
    findUser: "მოძებნეთ მომხმარებელი...",
    typeText: "შეიყვანეთ ტექსტი",
    deletChatText: "ნამდვილად გსურთ ჩათის წაშლა?",
    confirm: "დადასტურება",
    confirmText: "ნამდვილად გსურთ ტექსტის წაშლა?",
    cancel: "გაუქმება",
    remove: "წაშლა",
    notFound: "სასაუბროები არ მოიძებნა",
    aiAssistant: "Beauty ასისტენტი",
    assistantOffer:
      "არ იმყოფება ონლაინ, მანამდე შეგიძლიათ გაესაუბროთ AI ასისტენტს.",
    disableBot: "ბოტის გათიშვა",
    assistantTyping: "ასისტენტი წერს",
    consultantTyping: "კონსულტანტი წერს",
    aiConsultant: "AI კონსულტანტი",
  },
  ru: {
    title: "Чат",
    recently: "Недавно",
    followings: "Избранное",
    findUser: "Найти пользователя...",
    typeText: "Введите текст...",
    deleteChatText: "Вы уверены, что хотите удалить чат?",
    confirm: "Подтвердить",
    confirmText: "Вы уверены, что хотите удалить текст?",
    cancel: "Отмена",
    remove: "Удалить",
    notFound: "Чаты не найдены",
    aiAssistant: "Beauty Помощник",
    assistantOffer:
      "не в сети, вы можете начать разговор с помощью AI Assistant",
    disableBot: "Отключить бота",
    assistantTyping: "Ассистент печатает",
    consultantTyping: "Консультант печатает",
    aiConsultant: "AI Консультант",
  },
};

export default Chat;
