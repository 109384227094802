import React from 'react';
import styled from 'styled-components';

export const SendEmails = () => {
  return <Container>SendEmails</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  background: black;
`;
