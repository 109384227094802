import React from 'react';

const NotFoundPage = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        letterSpacing: '0.5px',
        fontWeight: 500,
        color: '#f866b1',
      }}
    >
      <h3>404 - Not Found!</h3>
    </div>
  );
};

export default NotFoundPage;
