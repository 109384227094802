const Ads = {
  en: {
    title: "Advertisement",
  },
  ka: {
    title: "რეკლამა",
  },
  ru: {
    title: "Реклама",
  },
};

export default Ads;
