const Reviews = {
  en: {
    title: "Recent Reviews",
  },
  ka: {
    title: "ბოლო შეფასებები",
  },
  ru: {
    title: "Последние отзывы",
  },
};

export default Reviews;
