import axios from 'axios';
import { deleteObject, listAll, ref } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { storage } from '../../firebase';
import { Language } from '../../context/language';
import { Options } from '../../pages/feeds/components/options';
import { PostSection } from '../../pages/feeds/components/postSection';
import VideoComponent from '../../pages/feeds/components/videoComponent';
import { setOpenedFeed } from '../../redux/feed';
import { setFeeds } from '../../redux/feeds';
import { BounceLoader } from 'react-spinners';

export const FeedCard = ({
  item,
  setItem,
  goToFeeds,
  setOpenFeed,
  setOpenReviews,
}) => {
  // define file sizes
  const fileWidth = item?.fileWidth;
  const fileHeight = item?.fileHeight;
  const aspectRatio = fileWidth / fileHeight; // 0.6
  const screenWidth = window.innerWidth;
  const screenHeight = screenWidth / aspectRatio;
  // define navigate
  const navigate = useNavigate();

  // defines location
  const location = useLocation();

  // language
  const language = Language();

  // redux dispatch
  const dispatch = useDispatch();

  // image loading opacity
  const [opacity, setOpacity] = useState(false);

  /**
   * user actions on feed
   */

  const [starsLength, setStarsLength] = useState(item?.starsLength || 0);
  const [savesLength, setSavesLength] = useState(item?.saves?.length || 0);
  const [reviewsLength, setReviewsLength] = useState(item?.reviewsLength || 0);

  useEffect(() => {
    setStarsLength(item?.starsLength || 0);
    setSavesLength(item?.saves?.length || 0);
    setReviewsLength(item?.reviewsLength || 0);
  }, [item]);

  /**
   * item media sizes
   */

  const widthReduction = 20;
  const reductionPercent = (widthReduction / screenWidth) * 100;
  const adjustedHeight = screenHeight - screenHeight * (reductionPercent / 100);

  // pagin enabled scrolling images
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollRef = useRef();

  const handleScroll = (event) => {
    const offsetX = event.target.scrollLeft;
    const index = Math.round(offsetX / window.innerWidth);
    if (index !== activeIndex) setActiveIndex(index);
  };

  useEffect(() => {
    const scrollView = scrollRef.current;
    const handleScrollEnd = () => {
      const offsetX = scrollView.scrollLeft;
      const index = Math.round(offsetX / window.innerWidth);
      const offset = index * window.innerWidth;
      scrollView.scrollTo({
        left: offset,
        behavior: 'smooth',
      });
    };
    scrollView.addEventListener('scrollend', handleScrollEnd);

    return () => scrollView.removeEventListener('scrollend', handleScrollEnd);
  }, []);

  const currentUser = useSelector((state) => state.storeUser.currentUser);
  const backendUrl = useSelector((state) => state.storeApp.backendUrl);
  const feeds = useSelector((state) => state.storeFeeds.feeds);

  // open feed option
  const [openOption, setOpenOption] = useState(false);

  let type;
  if (item?.type === 'specialist') {
    type = language?.language?.Main?.feedCard?.specialist;
  } else if (item?.type === 'shop') {
    type = language?.language?.Marketplace?.marketplace?.shop;
  } else if (item?.type === 'beautycenter') {
    type = language?.language?.Auth?.auth?.beautySalon;
  } else if (item?.type === 'blogger') {
    type = 'Blogger';
  } else {
    type = language?.language?.Auth?.auth?.user;
  }

  function convertUTCtoNormalDateTime(utcString) {
    const date = new Date(utcString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // Example usage
  const normalDate = convertUTCtoNormalDateTime(item.createdAt);

  /*
   * Delete post
   */
  const [confirmDelete, setConfirmDelete] = useState({
    active: false,
    data: null,
  });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const Deleting = async () => {
    setDeleteLoading(true);

    // Create a reference to the file to delete
    let fileRef;
    if (confirmDelete.data?.fileFormat === 'video') {
      fileRef = ref(
        storage,
        `videos/${currentUser?._id}/feeds/${confirmDelete.data?.name}/`
      );
    } else {
      fileRef = ref(
        storage,
        `images/${currentUser?._id}/feeds/${confirmDelete.data?.name}`
      );
    }

    // remove feed from DB
    try {
      const url = backendUrl + `/api/v1/feeds/${confirmDelete.data?._id}`;
      const resp = await axios.delete(url);
      const newList = feeds?.filter((i) => i._id !== confirmDelete.data?._id);
      dispatch(setFeeds(newList));

      await axios.post(
        `${backendUrl}/api/v1/users/${confirmDelete.data?.owner?._id}/notifications`,
        {
          senderId: 'Beautyverse',
          text: 'Your post has been deleted by the BeautyVerse team because it contains content that do not align with our community guidelines. We encourage you to review and adhere to the BeautyVerse terms and rules when sharing your content. Please feel free to edit and repost in accordance with our guidelines. Thank you for your understanding and contribution to making BeautyVerse a supportive and beauty-focused community!',
          date: new Date(),
          type: 'welcome',
          status: 'unread',
          feed: '',
        }
      );
      setTimeout(() => {
        setConfirmDelete({ active: false, data: null });
        setDeleteLoading(false);
      }, 1000);
      if (resp) {
        // Delete the file from cloud
        if (confirmDelete.data?.fileFormat === 'video') {
          deleteObject(fileRef).then(() => {
            setTimeout(() => {
              setDeleteLoading(false);
            }, 300);
          });
        } else {
          listAll(fileRef)
            .then((res) => {
              res.items.forEach((itemRef) => {
                deleteObject(itemRef).then(() => {
                  setTimeout(() => {
                    setDeleteLoading(false);
                  }, 300);
                });
              });
            })
            .catch((error) => {
              console.log('error : ' + error);
            });
        }
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  console.log(adjustedHeight?.toFixed(0));

  return (
    <Container>
      <div
        style={{
          color: '#ccc',
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          borderTop: '1.5px solid rgba(255,255,255,0.1)',
          borderBottom: '1.5px solid rgba(255,255,255,0.1)',
          margin: '15px 0',
        }}
      >
        <img
          src={item.owner.cover}
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
            borderRadius: '50px',
          }}
        />
        <h4>{item.owner.name}</h4>
        <h5>{item.owner.username ? item.owner.username : type}</h5>
        <h5>{normalDate}</h5>
        <MdDelete
          onClick={() => setConfirmDelete({ active: true, data: item })}
          color="red"
          size={24}
          style={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}
        />
      </div>
      {item?.post?.original?.length > 0 && <PostSection item={item} />}
      <FilesContainer
        ref={scrollRef}
        onScroll={handleScroll}
        width={screenWidth - widthReduction}
        height={adjustedHeight}
        style={{ overflowX: item?.images?.length > 1 ? 'scroll' : 'hidden' }}
      >
        {confirmDelete?.active && (
          <ConfirmDelete
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            Deleting={Deleting}
            loading={deleteLoading}
            setLoading={setDeleteLoading}
          />
        )}

        {item?.fileFormat === 'img' ? (
          <>
            {item?.images?.map((itm, index) => {
              return (
                <Image
                  onClick={() => {
                    if (!goToFeeds) {
                      if (
                        location.pathname === '/feeds' ||
                        location.pathname.includes('profile/settings')
                      ) {
                        navigate(
                          location.pathname.includes('/profile')
                            ? `feed/${item?._id}`
                            : `/feeds/${item?._id}`
                        );
                        dispatch(setOpenedFeed(item));
                      } else {
                        return undefined;
                      }
                    } else {
                      goToFeeds();
                    }
                  }}
                  key={index}
                  src={itm?.url}
                  width="auto"
                  height="auto"
                  style={{
                    opacity: opacity ? 1 : 0,
                    transition: 'ease-in 300ms',
                    objectFit: 'cover',
                    maxWidth: '100%',
                  }}
                  onLoad={() => setOpacity(true)}
                />
              );
            })}
          </>
        ) : (
          <VideoComponent
            item={item}
            location={location}
            screenHeight={adjustedHeight?.toFixed(0)}
            screenWidth={screenWidth - widthReduction}
          />
        )}
      </FilesContainer>
      <div
        style={{
          width: '100%',
          color: '#ccc',
          padding: '18px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>Stars: ({starsLength})</div>
        <div>Comments: ({reviewsLength})</div>
        <div>Shares: ({item?.shares})</div>
        <div>Saves: ({savesLength})</div>
        <div>Views: ({item?.views?.length})</div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 700px;
`;

const FilesContainer = styled.div`
  width: ${(props) => props.width / 2.5}px;
  height: ${(props) => props.height / 2.5}px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-left: 10px;
  border-radius: 20px;
  max-height: 1080px;
  max-width: 640px;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  @media only screen and (max-width: 600px) {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 20px;
    max-width: auto;
    max-height: 640px;
  }
`;

const Image = styled.img`
  width: ${(props) => props.width / 2.5}px;
  height: ${(props) => props.height / 2.5}px;
  max-height: 1080px;
  max-width: 640px;

  @media only screen and (max-width: 600px) {
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    max-width: auto;
    max-height: 640px;
  }
`;

const ConfirmDelete = ({
  confirmDelete,
  setConfirmDelete,
  Deleting,
  loading,
}) => {
  return (
    <div
      onClick={
        loading
          ? undefined
          : () => setConfirmDelete({ active: false, data: null })
      }
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(1, 2, 12, 0.7)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        zIndex: 10010,
        cursor: 'pointer',
      }}
    >
      <ConfirmContainer
        onClick={(e) => e.stopPropagation()}
        style={{
          width: '500px',
          height: '250px',
          border: '1.5px solid rgba(255,255,255,0.1)',
          borderRadius: '15px',
          color: '#ccc',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          background: 'rgba(1,2,12,0.7)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          letterSpacing: '0.5px',
        }}
      >
        <h2>Confirm Delete</h2>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '25px',
            alignItems: 'center',
          }}
        >
          <ConfirmItem
            style={{
              color: '#fff',
              background: loading
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(255, 255, 255, 0.1)',
            }}
            onClick={
              loading
                ? undefined
                : () => setConfirmDelete({ active: false, data: null })
            }
          >
            Cancel
          </ConfirmItem>
          <ConfirmItem
            style={{
              color: 'red',
            }}
            onClick={Deleting}
          >
            {loading ? <BounceLoader color="#f866b1" size={18} /> : 'Delete'}
          </ConfirmItem>
        </div>
      </ConfirmContainer>
    </div>
  );
};

const scaleIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
   
  }
  to {
    transform: scale(1);
    opacity: 1;
   
  }
`;

const ConfirmContainer = styled.div`
  animation: ${scaleIn} 200ms;
`;

const ConfirmItem = styled.div`
  width: 35%;
  padding: 8px 10px;
  border-radius: 50px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: red;
  background: rgba(255, 255, 255, 0.1);
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;
