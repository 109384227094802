const Marketplace = {
  en: {
    popularProducts: "Popular Products",
    search: "Search...",
    categories: "Categories",
    brands: "Brands",
    priceRange: "Price Range",
    minPrice: "Min Price",
    maxPrice: "Max Price",
    discounts: "Discounts",
    onlyWith: "Only With",
    onlyWithout: "Only Without",
    sex: "Sex",
    women: "Women",
    men: "Men",
    type: "Type",
    forEveryone: "For Everyone",
    forProfessionals: "For Professionals",
    clear: "Clear",
    brand: "Brand",
    price: "Price",
    shortDescription: "Short Description",
    description: "Description",
    howToUse: "How To Use",
    compositions: "Compositions",
    shop: "Shop",
    files: "Files",
    title: "Title",
    all: "All",
    choiceCategory: "Choose Category",
    addBrandName: "Add Brand Name",
    addPrice: "Add Price",
    optional: "Optional",
    sale: "Sale",
    inStock: "In Stock",
    addInStock: "Add In Stock",
    variants: "Variants",
    choiceVariants: "Choose Variants",
    addShortDescriptions: "Add Short Descriptions",
    fullDescription: "Full Description",
    addFullDescription: "Add Full Description",
    addHowToUse: "Add How To Use",
    addCompositions: "Add Compositions",
    upload: "Upload",
    clearAllFields: "Clear All Fields",
    save: "Save",
    cover: "Cover",
    addProductName: "Add Product Name",
    addSale: "Add sale in %",
    editProduct: "Edit Product",
  },
  ka: {
    popularProducts: "პოპულარული პროდუქცია",
    search: "ძებნა...",
    categories: "კატეგორიები",
    brands: "ბრენდები",
    priceRange: "ფასის დიაპაზონი",
    minPrice: "მინიმალური ფასი",
    maxPrice: "მაქსიმალური ფასი",
    discounts: "ფასდაკლებები",
    onlyWith: "მხოლოდ ფასდაკლებით",
    onlyWithout: "ფასდაკლების გარეშე",
    sex: "სქესი",
    women: "ქალები",
    men: "კაცები",
    type: "ტიპი",
    forEveryone: "ყველასთვის",
    forProfessionals: "პროფესიონალური",
    clear: "გასუფთავება",
    brand: "ბრენდი",
    price: "ფასი",
    shortDescription: "მოკლე აღწერა",
    description: "აღწერა",
    howToUse: "გამოყენების ინსტრუქცია",
    compositions: "შემადგენლობები",
    shop: "მაღაზია",
    files: "ფაილები",
    title: "დასახელება",
    all: "ყველა",
    choiceCategory: "აირჩიე კატეგორია",
    addBrandName: "დაამატე ბრენდის სახელი",
    addPrice: "დაამატე ფასი",
    optional: "სურვილისამებრ",
    sale: "ფასდაკლება",
    inStock: "მარაგში",
    addInStock: "დაამატე რაოდენობა მარაგში",
    variants: "ვარიანტები",
    choiceVariants: "აირჩიე ვარიანტები",
    addShortDescriptions: "დაამატე მოკლე მიმოხილვა",
    fullDescription: "სრული აღწერა",
    addFullDescription: "დაამატე სრული აღწერა",
    addHowToUse: "დაამატე გამოყენების ინსტრუქცია",
    addCompositions: "დაამატე შემადგენლობები",
    upload: "ატვირთვა",
    clearAllFields: "სრულად გასუფთავება",
    save: "შენახვა",
    cover: "ქავერი",
    addProductName: "დაამატე პროდუქტის სახელი",
    addSale: "დაამატე ფასდაკლების %",
    editProduct: "პროდუქტის რედაქტირება",
  },
  ru: {
    // Russian translations
    popularProducts: "Популярные продукты",
    search: "Поиск...",
    categories: "Категории",
    brands: "Бренды",
    priceRange: "Диапазон цен",
    minPrice: "Мин. цена",
    maxPrice: "Макс. цена",
    discounts: "Скидки",
    onlyWith: "Только с",
    onlyWithout: "Только без",
    sex: "Пол",
    women: "Женщины",
    men: "Мужчины",
    type: "Тип",
    forEveryone: "Для всех",
    forProfessionals: "Для профессионалов",
    clear: "Очистить",
    brand: "Бренд",
    price: "Цена",
    shortDescription: "Краткое описание",
    description: "Описание",
    howToUse: "Как использовать",
    compositions: "Состав",
    shop: "Магазин",
    files: "Файлы",
    title: "Заголовок",
    all: "Все",
    choiceCategory: "Выберите категорию",
    addBrandName: "Добавить название бренда",
    addPrice: "Указать цену",
    optional: "Необязательно",
    sale: "Продажа",
    inStock: "В наличии",
    addInStock: "Добавить в наличие",
    variants: "Варианты",
    choiceVariants: "Выберите варианты",
    addShortDescriptions: "Добавить краткое описание",
    fullDescription: "Полное описание",
    addFullDescription: "Добавить полное описание",
    addHowToUse: "Добавить инструкцию по использованию",
    addCompositions: "Добавить состав",
    upload: "Загрузить",
    clearAllFields: "Очистить все поля",
    save: "Сохранить",
    cover: "Обложка",
    addProductName: "Добавить название продукта",
    addSale: "Добавить продажу в %",
    editProduct: "Редактировать продукт",
  },
};

export default Marketplace;
