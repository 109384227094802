import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Language } from '../../context/language';
import { UsersList } from './usersList';
import { User } from './user';
import { BounceLoader } from 'react-spinners';
import Switch from '@mui/material/Switch';
import { styled as StyledSwitch } from '@mui/styles';
import { FcNumericalSorting12, FcNumericalSorting21 } from 'react-icons/fc';

const PinkSwitch = StyledSwitch(Switch)(({ theme }) => ({
  // Styles for the switch when checked
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#f866b1', // Pink color for the thumb
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#f866b1', // Pink color for the track
  },

  // Styles for the switch when unchecked
  '& .MuiSwitch-switchBase': {
    color: 'gray', // Gray color for the thumb when unchecked
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'lightgray', // Light gray color for the track when unchecked
  },
}));

export const Users = () => {
  const [stats, setStats] = useState(null);

  const language = Language();

  const backendUrl = useSelector((state) => state.storeApp.backendUrl);

  useEffect(() => {
    const GetStats = async () => {
      try {
        const response = await axios.get(backendUrl + '/api/v1/admin/users');
        setStats(response.data);
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    GetStats();
  }, []);

  // open listis states

  const [openCountries, setOpenCountries] = useState({
    country: '',
    active: false,
  });
  const [openRegions, setOpenRegions] = useState({
    region: '',
    active: false,
  });
  const [openCities, setOpenCities] = useState({
    city: '',
    active: false,
  });
  const [openDistricts, setOpenDistricts] = useState({
    district: '',
    active: false,
  });
  const [openStreets, setOpenStreets] = useState({
    street: '',
    active: false,
  });

  // type filter
  const [filter, setFilter] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(false);
  const [sort, setSort] = useState(false);

  const [usersData, setUsersData] = useState({ list: [], total: 0 });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    console.log(sort);
    const GetUsers = async () => {
      // setLoading(true);
      try {
        const response = await axios.get(
          `${backendUrl}/api/v1/admin/userslist?page=1&limit=20&type=${
            filter ? filter : ''
          }&country=${
            openCountries.country ? openCountries.country : ''
          }&region=${openRegions?.region ? openRegions?.region : ''}&city=${
            openCities?.city ? openCities?.city : ''
          }&district=${
            openDistricts?.district ? openDistricts?.district : ''
          }&street=${openStreets?.street ? openStreets?.street : ''}&online=${
            onlineUsers ? 'true' : 'false'
          }&sort=${sort ? 'createdAt_asc' : 'createdAt_desc'}`
        );
        setUsersData(response.data.usersData);
        setPage(1);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      } catch (error) {
        console.log(error);
      }
    };
    GetUsers();
  }, [
    sort,
    filter,
    onlineUsers,
    openCountries?.country,
    openRegions?.region,
    openCities?.city,
    openDistricts?.district,
    openStreets?.street,
    backendUrl,
  ]);

  // change display to user
  const [goToUser, setGoToUser] = useState({ active: false, user: '' });

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <BounceLoader loading={loading} color="#f866b1" size={50} />
        </div>
      ) : (
        <>
          {goToUser.active ? (
            <User setGoToUser={setGoToUser} goToUser={goToUser} />
          ) : (
            <div style={{ display: 'flex', height: '100%' }}>
              <Container>
                <Wrapper>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      color: '#ccc',
                    }}
                  >
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50px',
                        background: 'green',
                      }}
                    ></div>
                    <div>
                      Online:{' '}
                      <span style={{ color: '#f866b1' }}>
                        {stats?.onlineUsers}
                      </span>{' '}
                      users
                    </div>
                    <PinkSwitch
                      checked={onlineUsers}
                      onChange={() => setOnlineUsers(!onlineUsers)}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      margin: '15px 0 0 0',
                      padding: '0 0 30px 0',
                    }}
                  >
                    <div
                      style={{
                        color: '#ccc',
                        letterSpacing: '0.5px',
                        fontSize: '22px',
                        fontWeight: 600,
                      }}
                    >
                      Users by GeoLocation:
                    </div>
                    <div style={{ color: '#888', fontSize: '18px' }}>
                      Total Countries:{' '}
                      <span style={{ color: '#f866b1' }}>
                        {stats && stats?.locationCounts[0]?.countriesLength}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                    >
                      {stats &&
                        stats?.locationCounts[0]?.countries
                          ?.sort((a, b) => b.usersLength - a.usersLength)
                          ?.map((item, index) => {
                            return (
                              <Item key={index}>
                                <Button
                                  active={
                                    openCountries.country === item.country
                                  }
                                  onClick={
                                    openCountries?.active &&
                                    openCountries.country === item.country
                                      ? () => {
                                          setOpenCountries({
                                            active: false,
                                            country: '',
                                          });
                                          setOpenRegions({
                                            active: false,
                                            region: '',
                                          });
                                          setOpenCities({
                                            active: false,
                                            city: '',
                                          });
                                          setOpenDistricts({
                                            active: false,
                                            region: '',
                                          });
                                          setOpenStreets({
                                            active: false,
                                            street: '',
                                          });
                                        }
                                      : () => {
                                          setOpenCountries({
                                            active: true,
                                            country: item.country,
                                          });
                                          setOpenRegions({
                                            active: false,
                                            region: '',
                                          });
                                          setOpenCities({
                                            active: false,
                                            city: '',
                                          });
                                          setOpenDistricts({
                                            active: false,
                                            district: '',
                                          });
                                          setOpenStreets({
                                            active: false,
                                            street: '',
                                          });
                                        }
                                  }
                                >
                                  <span>
                                    {item.country}:{' '}
                                    <span style={{ color: '#f866b1' }}>
                                      {item.usersLength}
                                    </span>
                                  </span>
                                  <span
                                    style={{ color: '#888', fontSize: '14px' }}
                                  >
                                    Country
                                  </span>
                                </Button>
                                {openCountries.country === item.country && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      margin: '15px',
                                    }}
                                  >
                                    {item?.regions
                                      ?.sort(
                                        (a, b) => b.usersLength - a.usersLength
                                      )
                                      ?.map((item, index) => {
                                        return (
                                          <Item key={index}>
                                            <Button
                                              active={
                                                openRegions.region ===
                                                item.region
                                              }
                                              onClick={
                                                openRegions?.active &&
                                                openRegions.region ===
                                                  item.region
                                                  ? () => {
                                                      setOpenRegions({
                                                        active: false,
                                                        region: '',
                                                      });
                                                      setOpenCities({
                                                        active: false,
                                                        city: '',
                                                      });
                                                      setOpenDistricts({
                                                        active: false,
                                                        region: '',
                                                      });
                                                      setOpenStreets({
                                                        active: false,
                                                        street: '',
                                                      });
                                                    }
                                                  : () => {
                                                      setOpenRegions({
                                                        active: true,
                                                        region: item.region,
                                                      });
                                                      setOpenCities({
                                                        active: false,
                                                        city: '',
                                                      });
                                                      setOpenDistricts({
                                                        active: false,
                                                        region: '',
                                                      });
                                                      setOpenStreets({
                                                        active: false,
                                                        street: '',
                                                      });
                                                    }
                                              }
                                            >
                                              <span>
                                                {item.region}:{' '}
                                                <span
                                                  style={{ color: '#f866b1' }}
                                                >
                                                  {item.usersLength}
                                                </span>
                                              </span>
                                              <span
                                                style={{
                                                  color: '#888',
                                                  fontSize: '14px',
                                                }}
                                              >
                                                Region
                                              </span>
                                            </Button>
                                            {openRegions.region ===
                                              item.region && (
                                              <div
                                                style={{
                                                  margin: '15px',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: '15px',
                                                }}
                                              >
                                                {item?.cities
                                                  ?.sort(
                                                    (a, b) =>
                                                      b.usersLength -
                                                      a.usersLength
                                                  )
                                                  ?.map((item, index) => {
                                                    return (
                                                      <Item key={index}>
                                                        <Button
                                                          active={
                                                            openCities.city ===
                                                            item.city
                                                          }
                                                          onClick={
                                                            openCities?.active &&
                                                            openCities.city ===
                                                              item.city
                                                              ? () => {
                                                                  setOpenCities(
                                                                    {
                                                                      active: false,
                                                                      city: '',
                                                                    }
                                                                  );

                                                                  setOpenDistricts(
                                                                    {
                                                                      active: false,
                                                                      region:
                                                                        '',
                                                                    }
                                                                  );
                                                                  setOpenStreets(
                                                                    {
                                                                      active: false,
                                                                      region:
                                                                        '',
                                                                    }
                                                                  );
                                                                }
                                                              : () => {
                                                                  setOpenCities(
                                                                    {
                                                                      active: true,
                                                                      city: item.city,
                                                                    }
                                                                  );

                                                                  setOpenDistricts(
                                                                    {
                                                                      active: false,
                                                                      street:
                                                                        '',
                                                                    }
                                                                  );
                                                                  setOpenStreets(
                                                                    {
                                                                      active: false,
                                                                      street:
                                                                        '',
                                                                    }
                                                                  );
                                                                }
                                                          }
                                                        >
                                                          <span>
                                                            {item.city}:{' '}
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#f866b1',
                                                              }}
                                                            >
                                                              {item.usersLength}
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: '#888',
                                                              fontSize: '14px',
                                                            }}
                                                          >
                                                            City
                                                          </span>
                                                        </Button>
                                                        {openCities.city ===
                                                          item.city && (
                                                          <div
                                                            style={{
                                                              margin: '15px',
                                                              display: 'flex',
                                                              flexDirection:
                                                                'column',
                                                              gap: '15px',
                                                            }}
                                                          >
                                                            {item?.districts
                                                              ?.sort(
                                                                (a, b) =>
                                                                  b.usersLength -
                                                                  a.usersLength
                                                              )
                                                              ?.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <Item
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      <Button
                                                                        active={
                                                                          openDistricts.district ===
                                                                          item.district
                                                                        }
                                                                        onClick={
                                                                          openDistricts?.active &&
                                                                          openDistricts.district ===
                                                                            item.district
                                                                            ? () => {
                                                                                setOpenDistricts(
                                                                                  {
                                                                                    active: false,
                                                                                    street:
                                                                                      '',
                                                                                  }
                                                                                );
                                                                                setOpenStreets(
                                                                                  {
                                                                                    active: false,
                                                                                    street:
                                                                                      '',
                                                                                  }
                                                                                );
                                                                              }
                                                                            : () => {
                                                                                setOpenDistricts(
                                                                                  {
                                                                                    active: false,
                                                                                    street:
                                                                                      item.district,
                                                                                  }
                                                                                );
                                                                                setOpenStreets(
                                                                                  {
                                                                                    active: false,
                                                                                    street:
                                                                                      '',
                                                                                  }
                                                                                );
                                                                              }
                                                                        }
                                                                      >
                                                                        <span>
                                                                          {
                                                                            item.district
                                                                          }
                                                                          :{' '}
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                '#f866b1',
                                                                            }}
                                                                          >
                                                                            {
                                                                              item.usersLength
                                                                            }
                                                                          </span>
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              '#888',
                                                                            fontSize:
                                                                              '14px',
                                                                          }}
                                                                        >
                                                                          District
                                                                        </span>
                                                                      </Button>
                                                                      {openDistricts.district ===
                                                                        item.district && (
                                                                        <div
                                                                          style={{
                                                                            margin:
                                                                              '15px',
                                                                            display:
                                                                              'flex',
                                                                            flexDirection:
                                                                              'column',
                                                                            gap: '15px',
                                                                          }}
                                                                        >
                                                                          {item?.streets
                                                                            ?.sort(
                                                                              (
                                                                                a,
                                                                                b
                                                                              ) =>
                                                                                b.usersLength -
                                                                                a.usersLength
                                                                            )
                                                                            ?.map(
                                                                              (
                                                                                item,
                                                                                index
                                                                              ) => {
                                                                                return (
                                                                                  <Item
                                                                                    key={
                                                                                      index
                                                                                    }
                                                                                  >
                                                                                    <Button
                                                                                      active={
                                                                                        openStreets.street ===
                                                                                        item.street
                                                                                      }
                                                                                      onClick={() =>
                                                                                        setOpenStreets(
                                                                                          {
                                                                                            active: true,
                                                                                            street:
                                                                                              item.street,
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      <span>
                                                                                        {
                                                                                          item.street
                                                                                        }

                                                                                        :{' '}
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              '#f866b1',
                                                                                          }}
                                                                                        >
                                                                                          {
                                                                                            item.users
                                                                                          }
                                                                                        </span>
                                                                                      </span>
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            '#888',
                                                                                          fontSize:
                                                                                            '14px',
                                                                                        }}
                                                                                      >
                                                                                        Street
                                                                                      </span>
                                                                                    </Button>
                                                                                  </Item>
                                                                                );
                                                                              }
                                                                            )}
                                                                        </div>
                                                                      )}
                                                                    </Item>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        )}
                                                      </Item>
                                                    );
                                                  })}
                                              </div>
                                            )}
                                          </Item>
                                        );
                                      })}
                                  </div>
                                )}
                              </Item>
                            );
                          })}
                    </div>
                  </div>
                </Wrapper>
              </Container>
              {/** Types */}
              <div
                style={{
                  width: '55%',
                  boxSizing: 'border-box',
                  padding: '15px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    color: '#ccc',
                    letterSpacing: '0.5px',
                    fontSize: '22px',
                    fontWeight: 600,
                  }}
                >
                  Users by type:
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    margin: '15px 0 5px 0',
                  }}
                >
                  <div
                    style={{
                      padding: '5px 20px',
                      borderRadius: '50px',
                      border: `1.5px solid rgba(255,255,255,0.1)`,
                      color: '#ccc',
                      letterSpacing: '0.5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    All:{' '}
                    <span style={{ color: '#ccc' }}>{stats?.totalUsers}</span>
                  </div>
                  {stats?.userTypes
                    ?.sort((a, b) => b.count - a.count)
                    ?.map((item, index) => {
                      let type;
                      if (item?.type === 'specialist') {
                        type = language?.language?.Main?.feedCard?.specialist;
                      } else if (item?.type === 'shop') {
                        type =
                          language?.language?.Marketplace?.marketplace?.shop;
                      } else if (item?.type === 'beautycenter') {
                        type = language?.language?.Auth?.auth?.beautySalon;
                      } else if (item?.type === 'blogger') {
                        type = 'Blogger';
                      } else {
                        type = language?.language?.Auth?.auth?.user;
                      }
                      return (
                        <div
                          key={index}
                          style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                            borderRadius: '50px',
                            border: `1.5px solid rgba(255,255,255,0.1)`,
                            color:
                              item.type === 'user'
                                ? 'green'
                                : item.type === 'beautycenter'
                                ? 'lightblue'
                                : item.type === 'specialist'
                                ? '#f866b1'
                                : item.type === 'blogger'
                                ? 'orange'
                                : 'red',
                            letterSpacing: '0.5px',
                          }}
                        >
                          {type}:{' '}
                          <span style={{ color: '#ccc' }}>{item.count}</span>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      overflowX: 'scroll',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      marginTop: '10px',
                    }}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      {sort ? (
                        <FcNumericalSorting21
                          size={24}
                          color="#ccc"
                          onClick={() => setSort(false)}
                        />
                      ) : (
                        <FcNumericalSorting12
                          size={24}
                          color="#ccc"
                          onClick={() => setSort(true)}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        padding: '5px 20px',
                        borderRadius: '50px',
                        border: `1.5px solid ${
                          filter ? 'rgba(255,255,255,0.1)' : '#f866b1'
                        }`,
                        color: '#ccc',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() => setFilter(null)}
                    >
                      All
                    </div>
                    {stats?.userTypes
                      ?.sort((a, b) => a.count + b.count)
                      ?.map((item, index) => {
                        let type;
                        if (item?.type === 'specialist') {
                          type = language?.language?.Main?.feedCard?.specialist;
                        } else if (item?.type === 'shop') {
                          type =
                            language?.language?.Marketplace?.marketplace?.shop;
                        } else if (item?.type === 'beautycenter') {
                          type = language?.language?.Auth?.auth?.beautySalon;
                        } else if (item?.type === 'blogger') {
                          type = 'Blogger';
                        } else {
                          type = language?.language?.Auth?.auth?.user;
                        }
                        return (
                          <div
                            key={index}
                            style={{
                              whiteSpace: 'nowrap',
                              padding: '5px 20px',
                              borderRadius: '50px',
                              border: `1.5px solid ${
                                filter !== item.type
                                  ? 'rgba(255,255,255,0.1)'
                                  : '#f866b1'
                              }`,
                              color:
                                item.type === 'user'
                                  ? 'green'
                                  : item.type === 'beautycenter'
                                  ? 'lightblue'
                                  : item.type === 'specialist'
                                  ? '#f866b1'
                                  : item.type === 'blogger'
                                  ? 'orange'
                                  : 'red',
                              cursor: 'pointer',
                            }}
                            onClick={() => setFilter(item.type)}
                          >
                            {type}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <UsersList
                  filter={filter}
                  country={openCountries.country}
                  region={openRegions.region}
                  city={openCities.city}
                  district={openDistricts.district}
                  street={openStreets.street}
                  usersData={usersData}
                  setUsersData={setUsersData}
                  loading={loading}
                  setLoading={setLoading}
                  page={page}
                  setPage={setPage}
                  setGoToUser={setGoToUser}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const Container = styled.div`
  width: 45%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  font-size: 18px;
  font-weight: 500;
  letterspacing: 0.75px;
  color: #ccc;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  width: auto;
  margin: 10px;
  box-sizing: border-box;
`;

const Item = styled.div`
  margin: 5px 0 0 0;
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Button = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  border: ${(props) =>
    !props.active
      ? '1.5px solid rgba(255, 255, 255, 0.1)'
      : '1.5px solid #f866b1'};
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`;
