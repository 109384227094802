const Auth = {
  en: {
    welcome: 'Welcome',
    slogan: 'Your World of Beauty!',
    subSlogan: 'Everything about beauty & self care!',
    goToApp: 'Go to App!',
    selectTheme: 'Select theme:',
    authentication: 'Authentication',
    selectLanguage: 'Select language:',
    dark: 'Dark theme',
    light: 'Light theme',
    keyFeatures: 'Key Features',
    socialMedia: 'Social Media',
    content: 'Photo/Video content',
    blogs: 'Beauty Blogs & News',
    sharing: 'Stars, Reviews & Sharing content',
    chat: 'Chat',
    personalProfile: 'Personal Beauty Profile',
    specialists: 'Specialists',
    businesses: 'Salons, Clinics & Studios',
    shops: 'Brands & Shops',
    bloggers: 'Bloggers',
    simpleUsers: 'Users',
    onlineBookings: 'Online Bookings',
    appointments: 'Online Appointments',
    bms: 'Booking managment system',
    statistics: 'Statistics',
    marketplace: 'Online Beauty Marketplace',
    products: 'Beauty & Self car products',
    delivery: 'Fastest Delivery',
    aiTools: 'AI Tools',
    aiAssistant: 'AI Beauty Assistant',
    aiBusiness: 'AI Business Consultant',
    form: 'Business Form',
    formText:
      'If you are interested to work with the BeautyVerse team, send us your contact details!',
    typeHere: 'Type here...',
    send: 'Send!',
    findInSocialMedia: 'Find us in social media',
    choice: 'Choice user type',
    specialist: 'Specialist',
    specText: 'Create a specialist account',
    beautySalon: 'Beauty Salon',
    salonText: 'Create a beauty salon account',
    shop: 'Shop',
    shopText: "Create your shop's showroom",
    user: 'User',
    userText: 'Create simple user account',
    selectProcedures: 'Select Procedures',
    logout: 'Logout',
    login: 'Login',
    phone: 'Phone number',
    phoneChange: 'Update phone number',
    phoneChangeText:
      'After verification, your phone number will be changed within 1-3 days.',
    forgot: 'Forgot your password?',
    havea: 'Already have an account?',
    dontHave: "Don't have an account?",
    valid: 'Please input a valid phone number',
    passwrong: "Passwords don't match!",
    inputCode: 'Please input the country code',
    wrongPhoneNumber: 'Incorrect phone number',
    noPhone: 'Phone number not found!',
    pleaseInput: 'Please input fields!',
    register: 'Register',
    identify: 'Identify',
    usedPhone: 'Phone number is already used!',
    emailUsed: 'Email is already used!',
    back: 'Back',
    next: 'Next',
    name: 'Name',
    nameWarning: 'The name must contain between 3 to 35 letters.',
    title: 'Title',
    address: 'Address',
    wrongAddress:
      'Invalid address: at least country, city and address are required',
    wrongEmail: 'Invalid email address',
    wrongEmailOrPass: 'Incorrect email or password',
    email: 'Email',
    wrongPassword: 'The password must consist of at least 8 characters',
    differentPasswords: 'Password and confirm password must be same!',
    password: 'Password',
    undefinedType: 'User type is undefined, please choose!',
    optional: 'Optional',
    aboutSalon: 'About the salon',
    confirmPassword: 'Confirm Password',
    passNotMatch: "Passwords don't match!",
    service: 'Services',
    addService: 'Add Procedure',
    workingDays: 'Working days',
    verification: 'Verification',
    verifyCodeSent: 'Verification code has been sent to the email',
    confirm: 'Confirm',
    successRegister: 'You have successfully registered!',
    congretulation: 'Congratulations!',
    continue: 'Continue',
    randomPasswordTitle: 'Password recovery',
    randomPasswordText:
      'Enter the email of the registered account for which you want to reset the password',
    send: 'Send',
    cancel: 'Cancel',
    updatePhoneNumberTitle: 'Do you want to change the phone number?',
    verificationCode: 'Enter the code',
    wrongVerifyCode: 'Invalid verification code',
    requestSent: 'Request successfully sent, please check your email!',
    enterEmail: 'Enter your email..',
    businessInfo: 'Business Information',
    english: 'English',
    georgian: 'Georgian',
    russian: 'Russian',
    delete: 'Delete',
    oldVersion: 'You are using an old version of the App!',
    updateNow: 'Update now!',
    min8symbols: 'Min 8 symbols',
    verify: 'Verify',
    acceptRules: 'Accept rules',
    eg: 'e.g:',
    findLocation: 'Find location...',
    searchProcedure: 'Search Procedure...',
  },
  ka: {
    welcome: 'მოგესალმებით',
    slogan: 'შენი სილამაზის სამყარო!',
    goToApp: 'აპლიკაციაში შესვლა',
    subSlogan: 'ყველაფერი სილამაზე & თავის მოვლის შესახებ!',
    selectTheme: 'აირჩიეთ თემა:',
    authentication: 'ავტორიზაცია',
    selectLanguage: 'აირჩიეთ ენა:',
    dark: 'მუქი თემა',
    light: 'ღია თემა',
    keyFeatures: 'ძირითადი ფუნქციები',
    socialMedia: 'სოცუალური ქსელი',
    content: 'ფოტო/ვიდეო კონტენტი',
    blogs: 'ბლოგები და სიახლეები',
    sharing: 'შეფასებები, კომენტარები & კონტენტის გაზიარება',
    chat: 'ჩატი',
    personalProfile: 'პერსონალური გვერდი',
    specialists: 'სპეციალისტები',
    businesses: 'სალონები, კლინიკები & სტუდიები',
    shops: 'ბრენდები & მაღაზიები',
    bloggers: 'ბლოგერები',
    simpleUsers: 'მომხმარებლები',
    onlineBookings: 'ონლაინ ჯავშნები',
    appointments: 'ონლაინ ჩაწერები',
    bms: 'ჯავშნების სამართავი სისტემა',
    statistics: 'სტატისტიკები',
    marketplace: 'ონლაინ კოსმეტიკის ბაზარი',
    products: 'სილამაზის & თავის მოვლის პროდუქტები',
    delivery: 'სწრაფი მიწოდება',
    aiTools: 'AI ხელსაწყოები',
    aiAssistant: 'AI ასისტენტი',
    aiBusiness: 'AI ბიზნეს კონსულტანტი',
    form: 'სათანამშრომლო ფორმა',
    formText:
      'თუ ხართ დაინტერესებული ბიუთივერსის გუნდთან თანამშრომლობით, მოგვწერეთ!',
    typeHere: 'დაწერეთ აქ...',
    send: 'გაგზავნა!',
    findInSocialMedia: 'გამოგვყევით სოციალურ ქსელში',
    choice: 'აირჩიე მომხმარებლის ტიპი',
    specialist: 'სპეციალისტი',
    specText: 'სპეციალისტის გვერდი',
    beautySalon: 'სილამაზის სალონი',
    salonText: 'ფიზიკური ობიექტის გვერდი',
    shop: 'მაღაზია',
    shopText: 'შექმენი შენი მაღაზიის ვიტრინა',
    user: 'მომხმარებელი',
    userText: 'მომხმარებლის გვერდი',
    selectProcedures: 'აირჩიე პროცედურები',
    logout: 'გასვლა',
    login: 'შესვლა',
    phone: 'მობილურის ნომერი',
    phoneChange: 'მობილურის ნომრის შეცვლა',
    phoneChangeText:
      'დადასტურების შემდეგ, თქვენი ტელეფონის ნომერი შეიცვლება 1-3 დღეში.',
    forgot: 'დაგავიწყდა პაროლი?',
    havea: 'გაქვთ ანგარიში შექმნილი?',
    dontHave: 'არ გაქვს ანგარიში?',
    valid: 'გთხოვთ შეიყვანოთ სწორი მობილურის ნომერი',
    passwrong: 'პაროლი არ ემთხვევა!',
    inputCode: 'შეიყვანეთ ქვეყნის კოდი',
    wrongPhoneNumber: 'არასწორი მობილურის ნომერი',
    noPhone: 'მობილურის ნომერი არ მოიძებნა!',
    pleaseInput: 'გთხოვღ შეავსოთ ცარიელი ველები!',
    register: 'რეგისტრაცია',
    identify: 'იდენტიფიკაცია',
    usedPhone: 'მობილური ნომერი უკვე გამოყენებულია!',
    emailUsed: 'ელ ფოსტა უკვე გამოყენებულია!',
    back: 'უკან',
    next: 'შემდეგი',
    name: 'სახელი',
    nameWarning: 'სახელი უნდა შეიცავდეს მინიმუმ 3 და მაქსიმუმ 35 ასოს.',
    title: 'დასახელება',
    address: 'მისამართი',
    wrongAddress:
      'არასწორი მისამართი: საჭიროა მინიმუმ ქვეყნის, ქალაქის და ქუჩის დასახელება',
    wrongEmail: 'არასწორი ელ-ფოსტა',
    wrongEmailOrPass: 'არასწორი ელ-ფოსტა ან პაროლი',
    email: 'ელ-ფოსტა',
    wrongPassword: 'პაროლი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან',
    differentPasswords: 'პაროლები არ ემთხვევა ერთმანეთს!',
    password: 'პაროლი',
    undefinedType: 'მომხმარებლის ტიპი უცნობია, გთხოვთ აირჩიოთ თავიდან',
    optional: 'სურვილისამებრ',
    aboutSalon: 'ინფორმაცია სალონის შესახებ',
    confirmPassword: 'დაადასტურე პაროლი',
    passNotMatch: 'პაროლები არ ემთხვევა!',
    service: 'სერვისები',
    addService: 'დაამატე პროცედურა',
    workingDays: 'სამუშაო დღეები',
    verification: 'ვერიფიკაცია',
    verifyCodeSent: 'ვერიფიკაციის კოდი გაიგზავნა ელ-ფოსტაზე',
    confirm: 'დადასტურება',
    successRegister: 'თქვენ წარმატებით დარეგისტრირდით!',
    congretulation: 'გილოცავთ!',
    continue: 'გაგრძელება',
    randomPasswordTitle: 'პაროლის აღდგენა',
    randomPasswordText:
      'შეიყვანეთ იმ ანგარიშის ელ-ფოსტა რომელზეც გსურთ პაროლის აღდგენა',
    send: 'გაგზავნა',
    cancel: 'გაუქმება',
    updatePhoneNumberTitle: 'გსურს მობილურის ნომრის შეცვლა?',
    verificationCode: 'შეიყვანეთ კოდი',
    wrongVerifyCode: 'არასწორი ვერიფიკაციის კოდი',
    requestSent:
      'მოთხოვნა წარმატებით გაიგზავნა, გთხოვთ, შეამოწმოთ თქვენი ელფოსტა!',
    enterEmail: 'Შეიყვანეთ თქვენი ელ-ფოსტა..',
    businessInfo: 'ბიზნეს ინფორმაცია',
    english: 'ინგლისური',
    georgian: 'ქართული',
    russian: 'რუსული',
    delete: 'წაშლა',
    oldVersion: 'თქვენ იყენებთ აპის ძველ ვერსიას!',
    updateNow: 'განახლება!',
    min8symbols: 'მინიმუმ 8 სიმბოლო',
    verify: 'ვერიფიკაცია',
    acceptRules: 'დაეთანხმეთ წესებს',
    eg: 'მაგ:',
    findLocation: 'მოძებნე მისამართი...',
    searchProcedure: 'პროცედურის ძებნა...',
  },
  ru: {
    welcome: 'Добро пожаловать',
    slogan: 'Ваш мир красоты!',
    subSlogan: 'Всё о красоте и уходе за собой!',
    goToApp: 'Перейти к приложению!',
    selectTheme: 'Выберите тему:',
    authentication: 'Аутентификация',
    selectLanguage: 'Выберите язык:',
    dark: 'Темная тема',
    light: 'Светлая тема',
    keyFeatures: 'Ключевые Особенности',
    socialMedia: 'Социальные Медиа',
    content: 'Фото/Видео Контент',
    blogs: 'Блоги о Красоте и Новости',
    sharing: 'Звезды, Отзывы и Поделиться Контентом',
    chat: 'Чат',
    personalProfile: 'Личный Профиль Красоты',
    specialists: 'Специалисти',
    businesses: 'Салоны, Клиники и Студии',
    shops: 'Бренды и Магазины',
    bloggers: 'Блоггеры',
    simpleUsers: 'Пользователи',
    onlineBookings: 'Онлайн Бронирование',
    appointments: 'Онлайн Записи',
    bms: 'Система Управления Бронированием',
    statistics: 'Статистика',
    marketplace: 'Онлайн Рынок Красоты',
    products: 'Продукты Красоты и Ухода за Собой',
    delivery: 'Быстрая Доставка',
    aiTools: 'AI Инструменты',
    aiAssistant: 'AI Ассистент по Красоте',
    aiBusiness: 'AI Бизнес Консультант',
    form: 'Бизнес Форма',
    formText:
      'Если вы заинтересованы работать с командой BeautyVerse, отправьте нам свои контактные данные!',
    typeHere: 'Напишите здесь...',
    send: 'Отправлять!',
    findInSocialMedia: 'Найдите нас в социальных сетях',
    choice: 'Выбор типа пользователя',
    specialist: 'Специалист',
    specText: 'Создать аккаунт специалиста',
    beautySalon: 'Салон красоты',
    salonText: 'Создать аккаунт салона красоты',
    shop: 'Магазин',
    shopText: 'Создайте выставочный зал своего магазина',
    user: 'Пользователь',
    userText: 'Создать аккаунт пользователя',
    selectProcedures: 'Выберите процедуры',
    logout: 'Выйти',
    login: 'Авторизоваться',
    phone: 'Номер телефона',
    phoneChange: 'Обновить номер телефона',
    phoneChangeText:
      'После проверки ваш номер телефона будет изменен в течение 1-3 дней.',
    forgot: 'Забыли пароль?',
    havea: 'У вас уже есть аккаунт?',
    dontHave: 'У вас нет учетной записи?',
    valid: 'Пожалуйста, введите действительный номер телефона',
    passwrong: 'Пароли не совпадают!',
    inputCode: 'Пожалуйста, введите код страны',
    wrongPhoneNumber: 'Неправильный номер телефона',
    noPhone: 'Номер телефона не найден!',
    pleaseInput: 'Пожалуйста, заполните это поле!',
    register: 'Регистрация',
    identify: 'Идентификация',
    usedPhone: 'Телефон уже используется!',
    emailUsed: 'Электронная почта уже используется!',
    back: 'Назад',
    next: 'Далее',
    name: 'Название',
    nameWarning: 'Имя должно содержать от 3 до 35 букв.',
    title: 'Название',
    address: 'Адрес',
    wrongAddress:
      'Неверный адрес: необходимо указать как минимум страну, город и название улицы',
    wrongEmail: 'Неверный адрес электронной почты',
    wrongEmailOrPass: 'Неправильный адрес электронной почты или пароль',
    email: 'Электронная почта',
    wrongPassword: 'Пароль должен состоять не менее чем из 8 символов',
    differentPasswords: 'Пароль и пароль для подтверждения должны совпадать!',
    password: 'Пароль',
    undefinedType: 'Тип пользователя не определен, пожалуйста, выберите!',
    optional: 'Необязательно',
    aboutSalon: 'О салоне',
    confirmPassword: 'Подтвердите пароль',
    passNotMatch: 'Пароли не совпадают!',
    service: 'Услуги',
    addService: 'Добавить процедуру',
    workingDays: 'Рабочие дни',
    verification: 'Проверка',
    verifyCodeSent: 'Код подтверждения отправлен на электронную почту',
    confirm: 'Подтвердить',
    successRegister: 'Вы успешно прошли регистрацию',
    congretulation: 'Поздравляем!',
    continue: 'Продолжать',
    randomPasswordTitle: 'Восстановление пароля',
    randomPasswordText:
      'Введите адрес электронной почты зарегистрированной учетной записи, для которой вы хотите сбросить пароль',
    send: 'Отправить',
    cancel: 'Отмена',
    updatePhoneNumberTitle: 'Хотите изменить номер телефона?',
    verificationCode: 'Введите код',
    wrongVerifyCode: 'Неверный код подтверждения',
    requestSent: 'Запрос успешно отправлен, проверьте свою электронную почту!',
    enterEmail: 'Введите адрес электронной почты..',
    businessInfo: 'Информация о бизнесе',
    english: 'Английский',
    georgian: 'Грузинский',
    russian: 'Русский',
    delete: 'Удалить',
    oldVersion: 'Вы используете старую версию приложения!',
    updateNow: 'Обновить!',
    min8symbols: 'Мин. 8 символов',
    verify: 'Верификация',
    acceptRules: 'Принять правила',
    eg: 'Например:',
    findLocation: 'Найти место...',
    searchProcedure: 'Поиск услуги...',
  },
};

export default Auth;
