const Prices = {
  en: {
    prices: "Prices",
    free: "Free",
    feeds: "Feeds",
    feedsText1: "Your profile will appear in the 'Followings' section.",
    feedsText2: "Your profile will not appear in the 'For You' section.",
    profileCards: "Profile Cards",
    cardsText:
      "Your profile will be displayed at the second tier in the cards section, ensuring good visibility within the community.",
    badge: "Verification Badge",
    badgeText:
      "The verification badge is not available for free users, ensuring premium users are distinguishable.",
    bookings: "Bookings",
    bookingsText: "Users cannot send you appointment requests.",
    monthly: "Monthly",
    anually: "Annually",
    subscription: "Subscription",
    perMonth: "Per Month",
    cardsText2:
      "Your profile will be displayed at the top tier in the cards section, ensuring maximum visibility within the community.",
    badgeText2: "More details about the verification badge.",
    bookingsText2: "Learn more about bookings.",
    activation: "Activation",
    cancel: "Cancel",
    mo: "mo.",
    y: "y.",
    save: "Save",
  },
  ka: {
    prices: "ფასები",
    free: "უფასო",
    feeds: "პოსტები",
    feedsText1: "პოსტები ჩანს 'ფოლოუერების' სექციაში.",
    feedsText2: "პოსტები ჩანს 'შენთვის' სექციაში.",
    profileCards: "პროფაილები",
    cardsText: "უფასო პროფაილები ჩანს მხოლოდ ფასიანი გამომწერების შემდგომ.",
    badge: "ვერიფიკაციის ბეიჯი",
    badgeText: "ვერიფიკაციის ბეიჯი მინიჭებული მეტი სანდოობისთვის.",
    bookings: "ჯავშნები",
    bookingsText:
      "მომხმარებლებს არ აქვთ შესაძლებლობა გამოგიგზავნონ ჯავშანი სისტემის გამოყენებით.",
    monthly: "თვიურად",
    anually: "წლიურად",
    subscription: "ვერიფიკაცია",
    perMonth: "ყოველ თვე",
    cardsText2: "ფასიანი პროფაილები ჩანს სიის საწყის დონეზე.",
    badgeText2: "ვერიფიკაციის ბეიჯი მეტი სანდოობისთვის.",
    bookingsText2:
      "მომხმარებლებს შესაძლებლობა აქვს დაჯავნონ თვისუფალი დრო სისტემის გამოყენებით",
    activation: "აქტივაცია",
    cancel: "გაუქმება",
    mo: "თვ.",
    y: "წ.",
    save: "დაზოგე",
  },
  ru: {
    prices: "Цены",
    free: "Бесплатно",
    feeds: "Ленты",
    feedsText1: "Ваш профиль будет отображаться в разделе 'Подписчики'.",
    feedsText2: "Ваш профиль не будет отображаться в разделе 'Для вас'.",
    profileCards: "Профильные карты",
    cardsText:
      "Ваш профиль будет отображаться на втором уровне в разделе карточек, обеспечивая приличную видимость среди сообщества.",
    badge: "Значок верификации",
    badgeText:
      "Значок верификации недоступен для бесплатных пользователей, что позволяет выделиться платным пользователям.",
    bookings: "Бронирования",
    bookingsText: "Пользователи не могут отправлять вам заявки на встречу.",
    monthly: "Ежемесячно",
    anually: "Ежегодно",
    subscription: "Подписка",
    perMonth: "За месяц",
    cardsText2:
      "Ваш профиль будет отображаться на первом уровне в разделе карточек, обеспечивая лучшую видимость среди сообщества.",
    badgeText2: "Более подробная информация о значке.",
    bookingsText2: "Подробнее о бронированиях",
    activation: "Активация",
    cancel: "Отмена",
    mo: "мес.",
    y: "год.",
    save: "Сохранить",
  },
};

export default Prices;
