const User = {
  en: {
    specialist: "Specialist",
    beautySalon: "Beauty Salon",
    user: "User",
    about: "About",
    username: "Username",
    name: "Name",
    address: "Address",
    add: "Add",
    addFeed: "Add Feed",
    addAddress: "Add address",
    findAddress: "Find address...",
    feeds: "Feeds",
    contact: "Contact",
    support: "Support",
    savedItems: "Saved Items",
    team: "Team",
    service: "Services",
    audience: "Audience",
    statistics: "Statistics",
    settings: "Settings",
    removeTitle: "Confirm",
    removeText: "Are you sure you want to delete this post?",
    removeDays: "Are you sure you want to delete working day?",
    cancel: "Cancel",
    change: "Change",
    remove: "Remove",
    memberOffer: "Invite a new team member",
    addMember: "Add team member",
    findMember: "Find team member",
    memberOfferedServices: "Procedures offered by a specialist",
    memberServices: "Procedures",
    removeMember: "Are you sure you want to delete the member?",
    experienceInBeauty: "Experience in the beauty industry",
    experience: "Experience",
    year: "Years",
    sendOffer: "Send Offer!",
    successOffer: "Offer sent successfully!",
    invite: "has sent you an invitation to join his team!",
    workingInfo: "Working Info",
    workingDays: "Working days",
    workingPlace: "Working environment",
    workingHours: "Working hours",
    startAt: "Start at:",
    endAt: "End at:",
    addService: "Add Procedure",
    price: "Price",
    confirm: "Confirm!",
    removeServiceText: "Are you sure you want to delete the procedure?",
    lastProcedure: "The last service cannot be deleted",
    followers: "Followers",
    followings: "Followings",
    password: "Password",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    successChange: "Password changed successfully",
    addFeed: "Add Feed",
    reviews: "Reviews",
    removeFeed: "Are you sure you want to remove this feed?",
    feedAdded: "Feed added sucessfully!",
    addText: "Add text here...",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    allTime: "All time",
    all: "All",
    todayVisitors: "Today visitors",
    todayFollowers: "Today followers",
    todayStars: "Today stars",
    lastMonthStats: "Last month's stats",
    visitors: "Visitors",
    stars: "Stars",
    lastWeekVisitors: "Last week's visitors",
    lastWeekFollowers: "Last week's followers",
    lastWeekStars: "Last week's stars",
    lastMonthsStats: "Last months stats",
    upload: "Upload",
    selectImage: "Image",
    selectVideo: "Video",
    profile: "Profile",
    cards: "Cards",
    main: "Main",
    personalInfo: "Personal Info",
    addresses: "Addresses",
    procedures: "Procedures",
    products: "Products",
    showroom: "Showroom",
    security: "Security",
    languages: "Languages",
    notifications: "Notifications",
    darkMode: "Dark Mode",
    activation: "Activation",
    logout: "Logout",
    verification: "Subscription",
    userType: "User type",
    currency: "Currency",
    prices: "Prices",
    sentBookings: "Sent Bookings",
    country: "Country",
    region: "Region",
    city: "City",
    district: "District",
    street: "Street",
    number: "N",
  },
  ka: {
    specialist: "სპეციალისტი",
    beautySalon: "სილამაზის სალონი",
    user: "მომხმარებელი",
    about: "შესახებ",
    username: "მეტსახელი",
    name: "სახელი",
    address: "მისამართი",
    add: "დამატება",
    addFeed: "დაამატე პოსტი",
    addAddress: "დაამატე მისამართი",
    findAddress: "მოძებნე მისამართი...",
    feeds: "პოსტები",
    contact: "კონტაქტი",
    support: "დახმარება",
    savedItems: "შენახული ნივთები",
    team: "გუნდი",
    service: "სერვისები",
    audience: "აუდიტორია",
    statistics: "სტატისტიკა",
    settings: "პარამეტრები",
    removeTitle: "დაადასტურეთ",
    removeText: "ნამდვილად გსურთ პოსტის წაშლა?",
    removeDays: "ნამდვილად გსურთ სამუსო დღის წაშლა?",
    cancel: "გაუქმება",
    change: "შეცვლა",
    remove: "წაშლა",
    memberOffer: "მოიწვიე ახალი გუნდის წევრი",
    addMember: "გუნდის წევრის დამატება",
    findMember: "იპოვნეთ გუნდის წევრი",
    memberOfferedServices: "სპეციალისტის მიერ შეთავაზებული პროცედურები",
    memberServices: "პროცედურები",
    removeMember: "ნამდვილად გსურთ გუნდის წევრის წაშლა?",
    experienceInBeauty: "გამოცდილება სილამაზის სფეროში",
    experience: "გამოცდილება",
    year: "წელი",
    sendOffer: "მოწვევის გაგზავნა!",
    successOffer: "მოწვევა წარმატებით გაიგზავნა!",
    invite: "გამოგიგზავნათ მოწვევა მის გუნდში გასაწევრიანებლად!",
    workingInfo: "სამუშაო ინფო",
    workingDays: "სამუშაო დღეები",
    workingHours: "სამუშაო საათები",
    startAt: "დასაწყისი:",
    endAt: "დასასრული:",
    workingPlace: "სამუშაო გარემო",
    addService: "დაამატე პროცედურა",
    price: "ფასი",
    confirm: "დადასტურება!",
    removeServiceText: "ნამდვილად გსურთ პროცედურის წაშლა?",
    lastProcedure: "ბოლო სერვისისი წაშლა შეუძლებელია",
    followers: "გამომწერები",
    followings: "გამოწერილები",
    password: "პაროლი",
    changePassword: "პაროლის ცვლილება",
    oldPassword: "ძველი პაროლი",
    newPassword: "ახალი პაროლი",
    confirmPassword: "დაადასტურე პაროლი",
    succesChange: "პაროლი წარმატებით შეიცვალა",
    addFeed: "პოსტის დამატება",
    reviews: "შეფასებები",
    removeFeed: "დარწმუნებული ხართ რომ გსურთ წაშალოთ პოსტი?",
    feedAdded: "პოსტი წარმატებით აიტვირთა!",
    addText: "დაწერეთ ტექსტი...",
    daily: "დღიური",
    weekly: "კვირეული",
    monthly: "თვიური",
    allTime: "ყველა",
    all: "ყველა",
    todayVisitors: "დღევანდელი ვიზიტორები",
    todayFollowers: "დღევანდელი ფოლოუერები",
    todayStars: "დღევანდელი ვარსკვლავები",
    lastMonthStats: "ბოლო თვის სტატისტიკა",
    visitors: "ვიზიტორები",
    stars: "ვარსკვლავები",
    lastWeekVisitors: "ბოლო კვირის ვიზიტორები",
    lastWeekFollowers: "ბოლო კვირის ფოლოუერები",
    lastWeekStars: "ბოლო კვირის ვარსკვლავები",
    lastMonthsStats: "ბოლო თვეების სტატისტიკა",
    upload: "ატვირთვა",
    selectImage: "სურათი",
    selectVideo: "ვიდეო",
    profile: "პირადი",
    cards: "პროფილები",
    main: "მთავარი",
    personalInfo: "პერსონალური ინფორმაცია",
    addresses: "მისამართები",
    procedures: "პროცედურები",
    products: "პროდუქტები",
    showroom: "ვიტრინა",
    security: "უსაფრთხოება",
    languages: "ენები",
    notifications: "შეტყობინებები",
    darkMode: "ღამის რეჟიმი",
    activation: "აქტივაცია",
    logout: "გასვლა",
    verification: "ვერიფიკაცია",
    userType: "მომხმარბელის ტიპი",
    currency: "ვალუტა",
    prices: "ფასები",
    sentBookings: "გაგზავნილი ჯავშნები",
    country: "ქვეყანა",
    region: "რეგიონი",
    city: "ქალაქი",
    district: "უბანი",
    street: "ქუჩა",
    number: "N",
  },
  ru: {
    specialist: "Специалист",
    beautySalon: "Салон красоты",
    user: "Пользователь",
    about: "Обо мне",
    username: "Имя пользователя",
    name: "Имя",
    address: "Адрес",
    add: "Добавить",
    addFeed: "Добавить ленту",
    addAddress: "Добавить адрес",
    findAddress: "Найти адрес...",
    feeds: "Ленты",
    contact: "Контакт",
    support: "Поддержка",
    savedItems: "Сохраненные элементы",
    team: "Команда",
    service: "Услуги",
    audience: "Аудитория",
    statistics: "Статистика",
    settings: "Настройки",
    removeTitle: "Подтвердить",
    removeText: "Вы уверены, что хотите удалить эту запись?",
    removeDays: "Вы уверены, что хотите удалить рабочий день?",
    cancel: "Отмена",
    change: "Изменить",
    remove: "Удалить",
    memberOffer: "Пригласить нового члена команды",
    addMember: "Добавить члена команды",
    findMember: "Найти члена команды",
    memberOfferedServices: "Процедуры, предлагаемые специалистом",
    memberServices: "Процедуры",
    removeMember: "Вы уверены, что хотите удалить члена команды?",
    experienceInBeauty: "Опыт работы в индустрии красоты",
    experience: "Опыт",
    year: "Лет",
    sendOffer: "Отправить предложение!",
    successOffer: "Предложение успешно отправлено!",
    invite: "пригласил вас присоединиться к его команде!",
    workingInfo: "Информация о работе",
    workingDays: "Рабочие дни",
    workingHours: "Рабочие часы",
    startAt: "Начало в:",
    endAt: "Конец в:",
    workingPlace: "Место работы",
    addService: "Добавить процедуру",
    price: "Цена",
    confirm: "Подтвердить!",
    removeServiceText: "Вы уверены, что хотите удалить процедуру?",
    lastProcedure: "Последняя процедура не может быть удалена",
    followers: "Подписчики",
    followings: "Мои подписки",
    password: "Пароль",
    changePassword: "Сменить пароль",
    oldPassword: "Старый пароль",
    newPassword: "Новый пароль",
    confirmPassword: "Подтвердите пароль",
    successChange: "Пароль успешно изменен!",
    addFeed: "Добавить в ленту",
    reviews: "Отзывы",
    removeFeed: "Вы уверены, что хотите удалить эту запись из ленты?",
    feedAdded: "Запись успешно добавлена в ленту!",
    addText: "Добавьте текст здесь...",
    daily: "Ежедневно",
    weekly: "Еженедельно",
    monthly: "Ежемесячно",
    allTime: "Все время",
    all: "Все",
    todayVisitors: "Посетители сегодня",
    todayFollowers: "Новые подписчики сегодня",
    todayStars: "Звезды сегодня",
    lastMonthStats: "Статистика за последний месяц",
    visitors: "Посетители",
    stars: "Звезды",
    lastWeekVisitors: "Посетители за последнюю неделю",
    lastWeekFollowers: "Подписчики за последнюю неделю",
    lastWeekStars: "Звезды за последнюю неделю",
    lastMonthsStats: "Статистика за последние месяцы",
    upload: "Загрузить",
    selectImage: "Изображение",
    selectVideo: "Видео",
    profile: "Профиль",
    cards: "Карточки",
    main: "Главная",
    personalInfo: "Личная информация",
    addresses: "Адреса",
    procedures: "Процедуры",
    products: "Продукты",
    showroom: "Витрина",
    security: "Безопасность",
    languages: "Языки",
    notifications: "Уведомления",
    darkMode: "Темный режим",
    activation: "Активация",
    logout: "Выйти",
    verification: "Верификация",
    userType: "Тип пользователя",
    currency: "Валюта",
    prices: "Цены",
    sentBookings: "Отправленные заказы",
    country: "Страна",
    region: "Регион",
    city: "Город",
    district: "Район",
    street: "Улица",
    number: "N",
  },
};

export default User;
