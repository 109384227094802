const Filter = {
  en: {
    title: 'Filter',
    city: 'City',
    district: 'District',
    specialist: 'Specialists',
    beautySalon: 'Beauty Salons',
    shop: 'Shops',
    search: 'Search...',
    filter: 'Filter',
    clear: 'Clear',
    location: 'Location',
    typeHere: 'Type here...',
    street: 'Street',
    streetNumber: 'Street Number',
    region: 'Region',
    country: 'Country',
    save: 'Save',
    edit: 'Edit',
  },
  ka: {
    title: 'ფილტრი',
    city: 'ქალაქი',
    district: 'უბანი',
    specialist: 'სპეციალისტები',
    beautySalon: 'სილამაზის სალონები',
    shop: 'მაღაზიები',
    search: 'ძებნა...',
    filter: 'ფილტრი',
    clear: 'გაუქმება',
    location: 'ლოკაცია',
    typeHere: 'ჩაწერეთ აქ...',
    street: 'ქუჩა',
    streetNumber: 'ქუჩის ნომერი',
    region: 'რეგიონი',
    country: 'ქვეყანა',
    save: 'შენახვა',
    edit: 'რედაქტირება',
  },
  ru: {
    title: 'Фильтр',
    city: 'Город',
    district: 'Район',
    specialist: 'Специалисты',
    beautySalon: 'Салоны красоты',
    shop: 'Магазины',
    search: 'Поиск...',
    filter: 'Фильтр',
    clear: 'Очистить',
    location: 'Местоположение',
    typeHere: 'Введите здесь...',
    street: 'Улица',
    streetNumber: 'Номер дома',
    region: 'Регион',
    country: 'Страна',
    save: 'Сохранить',
    edit: 'Редактировать',
  },
};

export default Filter;
