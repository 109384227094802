import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import List from '../../pages/feeds/list';
import { useDispatch, useSelector } from 'react-redux';
import { Language } from '../../context/language';
import { BounceLoader } from 'react-spinners';
import { FeedCard } from './feedCard';
import axios from 'axios';
import { AddFeeds, setPage } from '../../redux/feeds';

export const Feeds = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // language
  const language = Language();

  // current user state
  const currentUser = useSelector((state) => state.storeUser.currentUser);

  // backend url
  const backendUrl = useSelector((state) => state.storeApp.backendUrl);

  // fo to saved scroll y position
  const scrollYPosition = useSelector((state) => state.storeFeeds.scrollY);
  useEffect(() => {
    window.scrollTo(0, scrollYPosition);
  }, []);

  // feeds
  const loading = useSelector((state) => state.storeFeeds.loading);
  const page = useSelector((state) => state.storeFeeds.page);
  const feeds = useSelector((state) => state.storeFeeds.feeds);

  // adding feeds
  const AddUsersFeeds = async (currentPage) => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/v1/feeds?page=${currentPage}${
          currentUser ? '&check=' + currentUser._id : ''
        }&limit=3`
      );
      const newFeeds = response.data.data.feedlist;

      dispatch(AddFeeds(newFeeds));
      dispatch(setPage(currentPage));
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const scrollRef = useRef();

  useEffect(() => {
    const handleScroll = async () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        await AddUsersFeeds(page + 1);
      }
    };

    // Attach the scroll event listener
    const currentScrollRef = scrollRef.current;
    currentScrollRef.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      currentScrollRef.removeEventListener('scroll', handleScroll);
    };
  }, [page, scrollRef]);

  return (
    <Container ref={scrollRef}>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <BounceLoader color={'#f866b1'} loading={loading} size={50} />
        </div>
      ) : (
        <>
          <ContentContainer>
            <ListContainer>
              {feeds?.length > 0 ? (
                feeds?.map((item, index) => {
                  return (
                    <FeedContainer
                      data-id={item._id}
                      data-owner-id={item?.owner._id}
                      key={index}
                    >
                      <FeedCard item={item} />
                    </FeedContainer>
                  );
                })
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'rgba(255,255,255,0.3)',
                  }}
                >
                  <p>No feeds found!</p>
                </div>
              )}
            </ListContainer>
          </ContentContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 1000px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  overflow-y: scroll;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const ListContainer = styled.div`
  position: relative;
  transition: ease-in 200ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
`;

const FeedContainer = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
`;
