const Ads = {
  en: {
    terms: "Terms and rules",
    usage: "How does Beautyverse work?",
    privacy: "Privacy Policy",
    qa: "Questions & Answers",
    notifications: "Notifications",
    accept: "Accept",
    dontAccept: "Close",
    close: "Close",
  },
  ka: {
    terms: "წესები და პირობები",
    usage: "როგორ მუშაობს ბიუთივერსი?",
    privacy: "კონფიდენციალურობა",
    qa: "კითხვებზე პასუხები",
    notifications: "შეტყობინებები",
    accept: "ვეთანხმები",
    dontAccept: "დახურვა",
    close: "დახურვა",
  },
  ru: {
    terms: "Условия и правила",
    usage: "Как работает Beautyverse?",
    privacy: "Политика конфиденциальности",
    qa: "Вопросы и ответы",
    notifications: "Уведомления",
    accept: "Принять",
    dontAccept: "Закрыть",
    close: "Закрыть",
  },
};

export default Ads;
