const Bookings = {
  en: {
    createBooking: "Create Booking",
    choiceProcedure: "Choice procedure",
    choiceDate: "Date",
    prev: "Prev",
    next: "Next",
    choiceTime: "Choice Time",
    notAvailable: "Not Available",
    available: "Available",
    send: "Send request",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    bms: "Booking Managment",
    all: "All",
    NA: "N/A",
    result: "Result",
    active: "Active",
    pending: "Pending",
    completed: "Completed",
    new: "New",
    canceled: "Canceled",
    rejected: "Rejected",
    price: "Price",
    duration: "Duration",
    client: "Client",
    name: "Name",
    phone: "Phone Number",
    optional: "Optional",
    addational: "Addational info",
    comment: "Comment",
    addBooking: "Add Booking",
    date: "Date",
    procedure: "Procedure",
    bookedAt: "Booked At",
    areYouSure: "Are you sure to want to delete this booking?",
    bookingAdded: "The booking has added succesfully!",
    bookingSent: "The booking has sent succesfully!",
    notFound: "Bookings not found!",
    loadMore: "Load more...",
    loadLess: "Load less",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
  },
  ka: {
    createBooking: "ჯავშნის შედგენა",
    choiceProcedure: "აირჩიე პროცედურა",
    choiceDate: "თარიღი",
    prev: "წინა",
    next: "შემდეგი",
    choiceTime: "აირჩიე დრო",
    notAvailable: "არ არის ხელმისაწვდომი",
    available: "ხელმისაწვდომი",
    send: "გაგზავნა",
    cancel: "გაუქმება",
    edit: "რედაქტირება",
    delete: "წაშლა",
    bms: "ჯავშნების კონტროლი",
    all: "ყველა",
    NA: "N/A",
    result: "შედეგი",
    active: "აქტიური",
    pending: "გაგზავნილი",
    completed: "დასრულებული",
    new: "ახალი",
    canceled: "გაუქმებული",
    rejected: "უარყოფილი",
    price: "ფასი",
    duration: "ხანგრძლივობა",
    client: "კლიენტი",
    name: "სახელი",
    phone: "მობილურის ნომერი",
    optional: "სურვილისამებრ",
    addational: "დამატებითი ინფორმაცია",
    comment: "კომენტარი",
    addBooking: "ჯავშნის დამატება",
    date: "თარიღი",
    procedure: "პროცედურა",
    bookedAt: "მიღების თარიღი",
    areYouSure: "ნამდვილად გსურთ ჯავშნის წაშლა?",
    bookingAdded: "ჯავშანი დაემატა წარმატებით!",
    bookingSent: "ჯავშანი გაიგზავნა წარმატებით!",
    notFound: "ჯავშნები არ მოიძებნა!",
    loadMore: "მეტის ჩატვირთვა...",
    loadLess: "ნაკლების ჩატვირთვა",
    today: "დღეს",
    tomorrow: "ხვალ",
    yesterday: "გუშინ",
  },
  ru: {
    createBooking: "Создать бронирование",
    choiceProcedure: "Выбор процедуры",
    choiceDate: "Даты",
    prev: "Предыдущий",
    next: "Далее",
    choiceTime: "Выбор времени",
    notAvailable: "Недоступно",
    available: "Доступно",
    send: "Отправить запрос",
    cancel: "Отменить",
    edit: "Редактировать",
    delete: "Удалить",
    bms: "Управление бронированием",
    all: "Все",
    NA: "N/A",
    result: "Результат",
    active: "Активный",
    pending: "В ожидании",
    completed: "Завершено",
    new: "Новый",
    canceled: "Отменено",
    rejected: "Отклонено",
    price: "Цена",
    duration: "Продолжительность",
    client: "Клиент",
    name: "Имя",
    phone: "Номер телефона",
    optional: "Необязательно",
    addational: "Дополнительная информация",
    comment: "Комментарий",
    addBooking: "Добавить бронирование",
    date: "Дата",
    procedure: "Процедура",
    bookedAt: "Заказано в",
    areYouSure: "Вы действительно хотите удалить этот заказ?",
    bookingAdded: "Бронирование успешно добавлено!",
    bookingSent: "ронирование успешно отправлено!",
    notFound: "Бронирования не найдены!",
    loadMore: "Загрузить еще...",
    loadLess: "Загружать меньше",
    today: "Сегодня",
    tomorrow: "Завтра",
    yesterday: "Вчера",
  },
};

export default Bookings;
