const Menu = {
  en: {
    profile: "Profile",
    notifications: "Notifications",
    tr: "Terms and rules",
    privacy: "Privacy",
    howitworks: "How does it work?",
    qa: "Q&A",
    logout: "Logout",
    login: "Login",
  },
  ka: {
    profile: "პირადი გვერდი",
    notifications: "შეტყობინებები",
    tr: "წესები და პირობები",
    privacy: "კონფიდენციალურობა",
    howitworks: "როგორ მუშაობს?",
    qa: "კითხვებზე პასუხები",
    logout: "გასვლა",
    login: "შესვლა",
  },
  ru: {
    profile: "Личный кабинет",
    notifications: "Уведомления",
    tr: "Правила и условия",
    privacy: "Конфиденциальность",
    howitworks: "Как это работает?",
    qa: "Вопросы и ответы",
    logout: "Выйти",
    login: "Войти",
  },
};

export default Menu;
