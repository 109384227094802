import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import styled, { keyframes } from 'styled-components';

export const User = ({ goToUser, setGoToUser }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const backendUrl = useSelector((state) => state.storeApp.backendUrl);

  useEffect(() => {
    const GetUser = async () => {
      try {
        const response = await axios.get(
          backendUrl + '/api/v1/admin/user/' + goToUser?.user
        );
        if (response.data.data.modifiedUser) {
          setUser(response.data.data.modifiedUser);
          setLoading(false);
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    };
    if (goToUser?.user?.length > 0) {
      GetUser();
    }
  }, []);

  console.log(user);

  function convertUTCtoNormalDateTime(utcString) {
    const date = new Date(utcString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const createdAt = convertUTCtoNormalDateTime(user?.createdAt);
  const lastLogin = convertUTCtoNormalDateTime(user?.lastLoginAt);
  const lastPost = convertUTCtoNormalDateTime(user?.lastPostCreatedAt);

  return (
    <Container>
      <div
        style={{
          boxSizing: 'border-box',
          padding: '15px 20px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#f866b1',
          borderBottom: '1.5px solid rgba(255,255,255,0.1)',
        }}
      >
        <MdArrowBack
          color="#f866b1"
          size={40}
          style={{ cursor: 'pointer' }}
          onClick={() => setGoToUser({ active: false, user: '' })}
        />
        {user && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {user?.cover?.length > 0 ? (
              <>
                {loading && (
                  <BounceLoader
                    size={30}
                    loading={loading}
                    style={{ position: 'absolute' }}
                    color="#f866b1"
                  />
                )}
                <img
                  src={user?.cover}
                  style={{
                    objectFit: 'cover',
                    width: '30px',
                    height: '30px',
                    borderRadius: '100px',
                  }}
                />
              </>
            ) : (
              <FaUserCircle size={30} color="#ccc" />
            )}
            <span
              style={{
                fontSize: '24px',
                fontWeight: 500,
                letterSpacing: '0.5px',
              }}
            >
              {user?.name}
            </span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: '#ccc',
          }}
        >
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50px',
              background: user?.online ? 'green' : 'gray',
            }}
          ></div>
          Online{' '}
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '80%',
            boxSizing: 'border-box',
          }}
        >
          <BounceLoader loading={loading} color="#f866b1" size={40} />
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            padding: '15px 100px',
          }}
        >
          <div
            style={{
              width: '100%',
              color: '#ccc',
              overflowY: 'scroll',
              maxHeight: '70vh',
              letterSpacing: '0.5px',
            }}
          >
            <p>
              _id: <span style={{ color: '#f866b1' }}>{user?._id}</span>
            </p>

            <p>
              Active:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.active)}
              </span>
            </p>
            <p>
              Admin:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.admin)}
              </span>
            </p>
            <p>
              Type: <span style={{ color: '#f866b1' }}>{user?.type}</span>
            </p>
            <p>
              Email: <span style={{ color: '#f866b1' }}>{user?.email}</span>
            </p>
            <div>
              Phone:{' '}
              <div
                style={{
                  color: '#ccc',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  margin: '15px',
                }}
              >
                <span style={{ color: '#f866b1' }}>
                  Number:{' '}
                  <span style={{ color: '#ccc' }}>{user?.phone?.phone}</span>
                </span>
                <span style={{ color: '#f866b1' }}>
                  Calling Code:{' '}
                  <span style={{ color: '#ccc' }}>
                    {user?.phone?.callingCode}
                  </span>
                </span>
                <span style={{ color: '#f866b1' }}>
                  Country Code:{' '}
                  <span style={{ color: '#ccc' }}>
                    {user?.phone?.countryCode}
                  </span>
                </span>
              </div>
            </div>
            <p>
              Username:{' '}
              <span style={{ color: '#f866b1' }}>{user?.username}</span>
            </p>
            <p>
              About: <span style={{ color: '#f866b1' }}>{user?.about}</span>
            </p>
            <p>
              Rating: <span style={{ color: '#f866b1' }}>{user?.rating}</span>
            </p>
            <p>
              Currency:{' '}
              <span style={{ color: '#f866b1' }}>{user?.currency}</span>
            </p>
            <p>
              Media:{' '}
              <span style={{ color: '#f866b1' }}>
                {' '}
                {JSON.stringify(user?.media)}
              </span>
            </p>
            <p>
              Procedures:{' '}
              <span style={{ color: '#f866b1' }}>
                {' '}
                {JSON.stringify(user?.procedures)}
              </span>
            </p>
            <p>
              Intereses:{' '}
              <span style={{ color: '#f866b1' }}>
                {' '}
                {JSON.stringify(user?.intereses)}
              </span>
            </p>
            <p>
              Push Notifications:{' '}
              <span style={{ color: '#f866b1' }}>
                {' '}
                {JSON.stringify(user?.pushNotifications)}
              </span>
            </p>
            <p>
              Subscription:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.subscription)}
              </span>
            </p>
            <p>
              Addresses:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.address)}
              </span>
            </p>
            <p>
              Created At: <span style={{ color: '#f866b1' }}>{createdAt}</span>
            </p>
            <p>
              Last Login At:{' '}
              <span style={{ color: '#f866b1' }}>{lastLogin}</span>
            </p>
            <p>
              Last Post Created At:{' '}
              <span style={{ color: '#f866b1' }}>{lastPost}</span>
            </p>
            <p>
              Register Stage:{' '}
              <span style={{ color: '#f866b1' }}>{user?.registerStage}</span>
            </p>
            <p>
              Register Device:{' '}
              <span style={{ color: '#f866b1' }}>{user?.registerDevice}</span>
            </p>
            <p>
              Email Verified:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.verifiedEmail)}
              </span>
            </p>
            <p>
              Working Days:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.workingDays)}
              </span>
            </p>
            <p>
              Experience:{' '}
              <span style={{ color: '#f866b1' }}>{user?.experience}</span>
            </p>
            <p>
              Followings:{' '}
              <span style={{ color: '#f866b1' }}>{user?.followers}</span>
            </p>
            <p>
              Followers:{' '}
              <span style={{ color: '#f866b1' }}>{user?.followings}</span>
            </p>
            <p>
              Feeds: <span style={{ color: '#f866b1' }}>{user?.feeds}</span>
            </p>
            <p>
              Notifications:{' '}
              <span style={{ color: '#f866b1' }}>{user?.notifications}</span>
            </p>
            <p>
              Visitors:{' '}
              <span style={{ color: '#f866b1' }}>{user?.visitors}</span>
            </p>
            <p>
              Accept Privacy:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.acceptPrivacy)}
              </span>
            </p>
            <p>
              Accept Terms:{' '}
              <span style={{ color: '#f866b1' }}>
                {JSON.stringify(user?.acceptTerms)}
              </span>
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

// title
const slideIn = keyframes`
  from {
    transform: translateX(100%);

   
  }
  to {
    transform: translateX(0);
  
   
  }
`;

const Container = styled.div`
  border-radius: 15px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  width: 100%;
  animation: ${slideIn} ease 300ms;
`;
