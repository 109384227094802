const FeedCard = {
  en: {
    specialist: "Specialist",
    beautySalon: "Beauty Salon",
    reviews: "Comments",
    report: "Report Feed",
    spam: "This is Spam",
    noThematical: "No Thematical Feed",
    reason: "Report a reason",
    aboutReason: "Describe the problem",
    writeReason: "Write about the problem..",
    send: "Send",
    cancel: "Cancel",
    success: "Report sent successfully!",
    min: " min",
    justNow: "Just now",
    h: " h",
    d: " d",
    w: " w",
    mo: " mo",
    y: " y",
    feed: "Feed",
    writeText: "Write text...",
    noComments: "No comments",
    star: "Star",
    comment: "Comment",
    share: "Share",
    forYou: "For You",
    seeOriginal: "See original",
  },
  ka: {
    specialist: "სპეციალისტი",
    beautySalon: "სილამაზის სალონი",
    reviews: "კომენტარი",
    report: "პოსტის გასაჩივრება",
    spam: "ეს არის სპამი",
    noThematical: "არათემატური პოსტი",
    reason: "მიზეზის აღწერა",
    aboutReason: "აღწერეთ საჩივრის მიზეზი",
    writeReason: "ჩაწერე საჩივარი..",
    send: "გაგზავნა",
    cancel: "გაუქმება",
    success: "საჩივარი წარმატებით გაიგზავნა!",
    min: " წთ",
    justNow: "ახლახანს",
    h: " სთ",
    d: " დ",
    w: " კვ",
    mo: " თვ",
    y: " წ",
    feed: "პოსტი",
    writeText: "დაწერე ტექსტი...",
    noComments: "კომენტარი არ მოიძებნა",
    star: "ვარსკვლავი",
    comment: "კომენტარი",
    share: "გაზიარება",
    forYou: "თქვენთვის",
    seeOriginal: "ორიგინალის ნახვა",
  },
  ru: {
    specialist: "Специалист",
    beautySalon: "Салон красоты",
    reviews: "Отзывы",
    report: "Пожаловаться на публикацию",
    spam: "Это спам",
    noThematical: "Публикация не по теме",
    reason: "Укажите причину",
    aboutReason: "Опишите проблему",
    writeReason: "Напишите о проблеме..",
    send: "Отправить",
    cancel: "Отмена",
    success: "Жалоба успешно отправлена!",
    min: " мин",
    justNow: "Только что",
    h: " ч",
    d: " д",
    w: " нед",
    mo: " мес",
    y: " г",
    feed: "Пост",
    writeText: "Напишите текст...",
    noComments: "Нет комментариев",
    star: "Звезда",
    comment: "Комментарий",
    share: "Делиться",
    forYou: "Для вас",
    seeOriginal: "Посмотреть оригинал",
  },
};

export default FeedCard;
