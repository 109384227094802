import { SupervisedUserCircle } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Language } from '../../context/language';
import { BounceLoader } from 'react-spinners';
import { MdSupervisedUserCircle } from 'react-icons/md';

export const UsersList = ({
  filter,
  country,
  region,
  city,
  district,
  street,
  usersData,
  setUsersData,
  loading,
  page,
  setPage,
  setGoToUser,
}) => {
  const backendUrl = useSelector((state) => state.storeApp.backendUrl);
  const language = Language();
  const scrollRef = useRef();

  const AddUsers = async (nextPage) => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/v1/admin/userslist?page=${nextPage}&limit=20$type=${
          filter ? filter : ''
        }&country=${country ? country : ''}&region=${
          region ? region : ''
        }&city=${city ? city : ''}&district=${
          district ? district : ''
        }&street=${street ? street : ''}`
      );

      setUsersData((prevData) => {
        // Create a map with existing users, keyed by _id
        const usersMap = new Map(prevData.list.map((user) => [user._id, user]));

        // Add new users, this will overwrite any existing user with the same _id
        response.data.usersData.list.forEach((user) => {
          usersMap.set(user._id, user);
        });

        // Convert the map back to an array
        const mergedList = Array.from(usersMap.values());

        return {
          ...prevData,
          list: mergedList,
        };
      });

      setPage(nextPage);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          AddUsers(page + 1);
        }
      }
    };

    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page]);

  return (
    <div
      ref={scrollRef}
      style={{
        height: '40vh',
        width: '100%',
        boxSizing: 'border-box',
        padding: '10px',
        display: ' flex',
        flexDirection: 'column',
        gap: '10px',
        overflowY: 'scroll',
      }}
    >
      {usersData?.list?.map((item, index) => {
        let type;
        if (item?.type === 'specialist') {
          type = language?.language?.Main?.feedCard?.specialist;
        } else if (item?.type === 'shop') {
          type = language?.language?.Marketplace?.marketplace?.shop;
        } else if (item?.type === 'beautycenter') {
          type = language?.language?.Auth?.auth?.beautySalon;
        } else if (item?.type === 'blogger') {
          type = 'Blogger';
        } else {
          type = language?.language?.Auth?.auth?.user;
        }

        function convertUTCtoNormalDateTime(utcString) {
          const date = new Date(utcString);

          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        // Example usage
        const normalDate = convertUTCtoNormalDateTime(item.createdAt);

        return (
          <Item
            key={index}
            onClick={() => setGoToUser({ active: true, user: item?._id })}
          >
            <MdSupervisedUserCircle size={22} />

            <div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50px',
                background: item.online ? 'green' : 'gray',
              }}
            />
            <div>{item.name}</div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 500,
                color:
                  item.type === 'user'
                    ? 'green'
                    : item.type === 'beautycenter'
                    ? 'lightblue'
                    : item.type === 'specialist'
                    ? '#f866b1'
                    : item.type === 'blogger'
                    ? 'orange'
                    : 'red',
              }}
            >
              {type}
            </div>
            <div style={{ fontSize: '14px' }}>{item.address[0].country}</div>
            <div
              style={{
                padding: '2.5px 10px',
                borderRadius: '50px',
                border: '1.5px solid rgba(255,255,255,0.1)',
                fontSize: '14px',
              }}
            >
              {normalDate}
            </div>
          </Item>
        );
      })}
    </div>
  );
};

const Item = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 8px 16px;
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  color: #ccc;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
