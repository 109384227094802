import React, { useState } from 'react';
import styled from 'styled-components';

export const Corrector = () => {
  const [input, setInput] = useState('');

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Convert input to lowercase, split, remove duplicates, and capitalize the first letter.
  const splited = [
    ...new Set(
      input
        .toLowerCase()
        .split(',')
        .map((item) => item.trim())
    ),
  ].map((item) => capitalizeFirstLetter(item));

  return (
    <Container>
      <textarea
        style={{ width: '500px', height: '500px' }}
        placeholder="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <div style={{ overflowY: 'scroll' }}>
        <p>
          {splited.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </p>
      </div>
      {splited?.length}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  background: red;
  display: flex;
  gap: 50px;
`;
